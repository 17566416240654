// Colors
$turquoise: #1ABC9C;
$green-sea: #16A085;
$emerald: #2ECC71;
$nephritis: #27AE60;
$peter-river: #3498DB;
$belize_hole: #2980B9;
$deep-well: #283A63;
$lavender: #7676f4;
$amethyst: #A66BBE;
$wisteria: #8E44AD;
$wet-asphalt: #34495E;
$midnight-blue: #2C3E50;
$sunflower: #F2CA27;
$orange: #F39C12;
$carrot: #E67E22;
$pumpkin: #D35400;
$alizarin: #E74C3C;
$pomegranate: #C0392B;
$clouds: #ECF0F1;
$silver: #BDC3C7;
$concrete: #95A5A6;
$asbestos: #7F8C8D;

$primary-color: #1A73EC !default;
$secondary-color: #8C44AB !default;
$dark-color: #454d5d !default;
$body-font-color: darken($dark-color, 5%) !default;
$link-color: darken($primary-color, 5%) !default;
$gray-color: lighten($dark-color, 40%) !default;
$gray-color-dark: darken($gray-color, 25%) !default;
$border-color: lighten($dark-color, 60%) !default;
$bg-color: lighten($dark-color, 66%) !default;
$code-color: $lavender !default;
$selection-color: #ECECFE;

// Sidebar
$sidebar-width: 14rem;
$sidebar-brand-height: 6.5rem;

// Fonts
$base-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto !default;
$fallback-font-family: "Helvetica Neue", sans-serif !default;
$body-font-family: "Public Sans",  $fallback-font-family !default;
$title-font-family: "Metropolis", $fallback-font-family !default;
$mono-font-family: "Hack", monospace !default;
$font-size: .85rem !default;
$min-responsive-font-size: 18px;
